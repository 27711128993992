<template>
  <v-card class="pa-2">
    <v-form ref="form" @submit.prevent="save">
      <v-row>
        <v-col cols="12">
          <v-text-field
            filled
            hide-details="auto"
            v-model="menu.nombre"
            class="v-superdense-input"
            label="Nombre"
            :rules="[rules.req]"
          ></v-text-field>
        </v-col>
        <v-col class="my-auto" cols="12">
          <h1 class="primary--text">Recetas</h1>
        </v-col>
        <v-divider class="my1"></v-divider>
        <template v-for="cat in categorias">
          <v-col cols="10" class="pb-0 text-left">
            <h3>{{ cat.nombre }}</h3>
          </v-col>
          <v-col cols="2" class="justify-center text-center"><v-btn @click.stop="addRec(cat.idCategoria)" small icon outlined color="primary"><v-icon>mdi-plus</v-icon></v-btn></v-col>
          <v-col cols="12" class="py-0">
            <v-divider></v-divider>
          </v-col>
          <template v-if="menu.recetas && menu.recetas.length">
            <template v-for="rec,i in menu.recetas.filter(r => r.categoria == cat.idCategoria)">
              <v-col cols="4" class="py-0"><div class="ms-5" style="width: 100%; height: 100%; display: flex;align-items: center;color: var(--v-info-base)">{{ recetas.filter(r => r.idReceta == rec.idReceta).map(a => a.nombre)[0] }}</div></v-col>
              <v-col cols="2" class="py-0"><div style="width: 100%; height: 100%; display: flex;align-items: center;justify-content: end;white-space: nowrap;"><v-text-field @focus="$event.target.select()" dense class="v-superdense-input ended-input" hide-details="" v-model="rec.uds" @input="keyHelper++"></v-text-field></div></v-col>
              <v-col cols="2" class="py-0"><div style="width: 100%; height: 100%; display: flex;align-items: center;justify-content: end;white-space: nowrap;">{{ parseFloat(pr(recetas.filter(r => r.idReceta == rec.idReceta).map(a => a.escandallos)[0]) || 0).toFixed(2).replace(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") }} €</div></v-col>
              <v-col cols="2" class="py-0"><div style="width: 100%; height: 100%; display: flex;align-items: center;justify-content: end;white-space: nowrap;">{{ (parseFloat((parseFloat(pr(recetas.filter(r => r.idReceta == rec.idReceta).map(a => a.escandallos)[0]) || 0)*(rec.uds || 0)) / (prTotal || 1)) * 100).toFixed(2).replace(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}%</div></v-col>
              <v-col cols="2" class="py-0 justify-center text-center"><v-btn icon color="error" @click="removeReceta(i)"><v-icon>mdi-close</v-icon></v-btn></v-col>
            </template>
          </template>
          <v-col cols="12" class="pb-1">
            <v-divider></v-divider>
          </v-col>
        </template>
        <v-col cols="6" style="border-top:2px solid var(--v-primary-base)" class="py-0"><div style="width: 100%; height: 100%; display: flex;align-items: center;color: var(--v-primary-base);font-weight: bold;">Coste Menú</div></v-col>
        <v-col cols="2" style="border-top:2px solid var(--v-primary-base)" class="py-0"><div style="width: 100%; height: 100%; display: flex;align-items: center;justify-content: end;white-space: nowrap;">{{ (menu.recetas && menu.recetas.length) ? parseFloat(prTotal || 0).toFixed(2).replace(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") : parseFloat(0).toFixed(2).replace(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") }} €</div></v-col>
        <v-col cols="2" style="border-top:2px solid var(--v-primary-base)" class="py-0"><div style="width: 100%; height: 100%; display: flex;align-items: center;justify-content: end;white-space: nowrap;">100%</div></v-col>
        <v-col cols="2"></v-col>

        <v-col cols="6" style="border-top:2px solid var(--v-primary-base)" class="py-0"><div style="width: 100%; height: 100%; display: flex;align-items: center;color: var(--v-primary-base);font-weight: bold;">Total Coste Menú (con desviación)</div></v-col>
        <v-col cols="2" style="border-top:2px solid var(--v-primary-base)" class="py-0"><div style="width: 100%; height: 100%; display: flex;align-items: center;justify-content: end;white-space: nowrap;">{{ (menu.recetas && menu.recetas.length) ? parseFloat((parseFloat(prTotal || 0) * (1+(menu.desviacion/100)))).toFixed(2).replace(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") : parseFloat(0).toFixed(2).replace(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") }} €</div></v-col>
        <v-col cols="2" style="border-top:2px solid var(--v-primary-base)" class="py-0"><div style="width: 100%; height: 100%; display: flex;align-items: center;justify-content: end;white-space: nowrap;"><v-text-field @focus="$event.target.select()" dense class="v-superdense-input ended-input" hide-details="" v-model="menu.desviacion"></v-text-field>%</div></v-col>
        <v-col cols="2"></v-col>

        <v-col cols="6" style="border-top:2px solid var(--v-primary-base)" class="py-0"><div style="width: 100%; height: 100%; display: flex;align-items: center;color: var(--v-primary-base);font-weight: bold;">Ratio F&B objetivo</div></v-col>
        <v-col cols="2" style="border-top:2px solid var(--v-primary-base)" class="py-0"></v-col>
        <v-col cols="2" style="border-top:2px solid var(--v-primary-base)" class="py-0"><div style="width: 100%; height: 100%; display: flex;align-items: center;justify-content: end;white-space: nowrap;"><v-text-field @focus="$event.target.select()" dense class="v-superdense-input ended-input" hide-details="" v-model="menu.ratioFnB"></v-text-field>%</div></v-col>
        <v-col cols="2"></v-col>
        
        <v-col cols="6" style="border-top:2px solid var(--v-primary-base)" class="py-0"><div style="width: 100%; height: 100%; display: flex;align-items: center;color: var(--v-primary-base);font-weight: bold;">Precio venta recomendado</div></v-col>
        <v-col cols="2" style="border-top:2px solid var(--v-primary-base)" class="py-0"><div style="width: 100%; height: 100%; display: flex;align-items: center;justify-content: end;white-space: nowrap;">{{ (menu.recetas && menu.recetas.length) ? (parseFloat(parseFloat((parseFloat(prTotal || 0) * (1+(menu.desviacion/100)))) / menu.ratioFnB)*100).toFixed(2).replace(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") : parseFloat(0).toFixed(2).replace(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") }} €</div></v-col>
        <v-col cols="2" style="border-top:2px solid var(--v-primary-base)" class="py-0"></v-col>
        <v-col cols="2"></v-col>
        
        <v-col cols="6" style="border-top:2px solid var(--v-primary-base)" class="py-0"><div style="width: 100%; height: 100%; display: flex;align-items: center;color: var(--v-primary-base);font-weight: bold;">Precio definitivo</div></v-col>
        <v-col cols="2" style="border-top:2px solid var(--v-primary-base)" class="py-0"><div style="width: 100%; height: 100%; display: flex;align-items: center;justify-content: end;white-space: nowrap;"><v-text-field @focus="$event.target.select()" dense class="v-superdense-input ended-input" hide-details="" v-model="menu.pDef"></v-text-field>€</div></v-col>
        <v-col cols="2" style="border-top:2px solid var(--v-primary-base)" class="py-0"></v-col>
        <v-col cols="2"></v-col>

        <v-col cols="12">
          <v-textarea
            filled
            hide-details="auto"
            v-model="menu.nota"
            label="Nota"
          ></v-textarea>
        </v-col>
        <v-col cols="12">
          <v-card-actions class="d-flex">
						<v-spacer></v-spacer>
						<v-btn v-if="menu.idReceta" @click.stop="menu = {}">
							<v-icon left>mdi-close</v-icon>
                Cancelar
						</v-btn>
						<v-btn @click.stop="save" color="primary">
							<v-icon left>mdi-content-save</v-icon>
							{{ menu.idReceta ? "Actualizar" : "Crear" }}
						</v-btn>
					</v-card-actions>
        </v-col>
      </v-row>
    </v-form>
    <AddReceta :categorias="categorias" :recetas="recetas" :articulos="articulos" :escandallos="escandallos" ref="addRec"></AddReceta>
  </v-card>
</template>

<script>
import { req } from "@/utils/validations.js";

export default {
  props: {
    value: Object,
    categorias: Array,
    articulos: Array,
    escandallos: Array,
    recetas: Array,
    menus: Array
  },
  components:{
    AddReceta: () => import("./AddReceta.vue"),
  },
  data() {
    return {
      default: {
        nombre : null,
      },
      menu: {
        ...this.value,
      },
      rules: {
        req,
      },
      keyHelper: 0,
    };
  },
  computed:{
    prTotal(){
      this.keyHelper++
      var total = 0
      this.menu.recetas.forEach(i => {
        total += (this.pr(this.recetas.filter(rec => rec.idReceta == i.idReceta)[0].escandallos))*i.uds

      });
      return total
    },
  },
  methods: {
    removeReceta(i){
      this.menu.recetas.splice(i,1)
      this.$forceUpdate()
    },
    pr(i){
      var total = 0
      i.forEach(esc => {
        try{
          total += eval(this.escandallos.filter(e => e.idEscandallo == esc.idEscandallo).map(a => a.formula)[0].replaceAll(',','.').replaceAll('p', this.articulos.filter(art => art.idArticulo == this.escandallos.filter(e => e.idEscandallo == esc.idEscandallo).map(a => a.idArticulo)[0]).map(a => a.precio))) * esc.uds
        }
        catch(e){
          total += 0
        }
      });
      return total
    },
    async addRec(id){
      const rec = await this.$refs.addRec.open(id);
      if (!this.menu.recetas) this.menu.recetas = []
      if (this.menu.recetas.filter(a => a.idReceta == rec).length == 0) this.$set(this.menu.recetas,this.menu.recetas.length, { idReceta : rec, categoria : id, uds : 1 })
      this.keyHelper++
      this.$forceUpdate()
    },
    async save() {
      if (!this.$refs.form.validate()) return;
      var aux = structuredClone(this.menu)
      if(!aux.recetas || aux.recetas.length == 0) {
        this.$root.$emit("snack", "ERROR: El menú debe tener al menos 1 receta")
        return;
      }
      var pt=0
      aux.recetas.forEach(rec => {
        var total = 0
        this.recetas.filter(r => r.idReceta == rec.idReceta).map(a => a.escandallos)[0].forEach(esc => {
          try {
            total += eval(this.escandallos.filter(e => e.idEscandallo == esc.idEscandallo).map(a => a.formula)[0].replaceAll(',','.').replaceAll('p', this.articulos.filter(art => art.idArticulo == this.escandallos.filter(e => e.idEscandallo == esc.idEscandallo).map(a => a.idArticulo)[0]).map(a => a.precio))) * esc.uds
          }
          catch(e) {
            total += 0
          }
        });
        rec.precio = total
        pt += total
      })
      if (!aux.pDef) aux.pDef = parseFloat((pt * (1+(aux.desviacion/100)) / (aux.ratioFnB || 1)) * 100).toFixed(2)
      this.menu.idReceta = null
      await axios({
        method: "POST",
        url: `/menus/`.replace(/\/+$/, ''),
        data: {
          ...aux,
        },
      });
      this.menu = {desviacion : 5, ratioFnB : 30}
      this.$emit("reload");
    },
  },
  mounted(){
    if (!this.menu.desviacion || isNaN(this.menu.desviacion)) this.menu.desviacion = 5
    if (!this.menu.ratioFnB || isNaN(this.menu.ratioFnB)) this.menu.ratioFnB = 30
  }
};
</script>

<style scoped>
.ended-input >>> input {
    color: var(--v-info-base);
    text-align:right;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}
</style>